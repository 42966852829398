export const addClassToCarausalSectionChecker = ["19"];
export const learnMoreDisplayShouldFlex = ["48", "34"];
export const learnMoreDisplayShouldTable = [
  "81",
  "58",
  "74",
  "61",
  "69",
  "86",
  "64",
  "65",
  "46",
  "60",
  "37",
];
export const removeDropDownClass = ["12", "84", "86", "65", "87"];
export const adaTenants = [
  "84",
  "40",
  "42",
  "86",
  "65",
  "87",
  "88",
  "85",
  "89",
  "17",
  "90",
  "91",
  "78",
  "92",
  "36",
  "94",
  "93",
  "1",
];
export const noFixedHeaderForMobileAccordion = ["85", "88"];
export const menuCheckerForOnClickNotHavingLi = [87, 88, 89, 90, 92, 94, 93];
export const customVisitorCheckers = ["87"];
export const notRequiredAmountConversion = [89];
export const mobileDefaultHeaderRetroactiveChange = [64, 72];
export const adaCheckerForLeftAndRightArrow = [42, 84, 85, 87, 36, 93, 1];
export const adaCheckerForSlideAnnounce = [85, 1];
export const showCarouselIndicatorNumber = [1];
export const learnMoreDisplayFeature = [85, 1];
export const broadcastReadMoreTextAndIconHandler = [1, 25, 27, 33, 38];
export const containerFluidFixesChecker = [
  47, 34, 40, 37, 38, 42, 51, 50, 39, 55, 63, 44, 36, 41, 35, 60, 46, 77, 41,
  45, 58, 25, 48, 31, 8, 26,
];
export const customPanelYearReplacerForReport = [
  68, 45, 84, 80, 63, 82, 42, 70, 58, 59, 65, 87, 88, 89, 90, 91, 93, 94, 71,
];
export const customPanelYearReplacerForSite = [
  84, 80, 63, 82, 42, 70, 87, 88, 89, 90, 91, 58, 93, 94, 71,
];
