export const ScriptHandler = (scriptsArray) => {
  let jsFiles = ["common.js", "output.js", "tenant_lib.min.js"];
  scriptsArray.forEach((item, index) => {
    if (
      item &&
      (!jsFiles.some((e) => item.includes(e)) ||
        process.env.REACT_APP_ACCESS_LOCAL_FILE === "false")
    ) {
      const script = document.createElement("script");
      script.src = item;
      script.nonce = "2646294A404E6352";
      script.key = index;
      script.async = true;
      document.getElementById("renderHtml").appendChild(script);
    } else if (item && process.env.REACT_APP_ACCESS_LOCAL_FILE === "true") {
      // import(`../symlink/tenant_lib.min.js`);
      // import(
      //   `../../${process.env.REACT_APP_FOLDER_NAME_URL}/assets/js/common.js`
      // );
      // import(
      //   `../../${process.env.REACT_APP_FOLDER_NAME_URL}/assets/js/output.js`
      // );
    }
  });
};

export const RemoveOldScriptAndLink = () => {
  let commonJsFile = document.querySelectorAll(`script[src*="/ui/js/"]`);
  let commonCssFile = document.querySelectorAll(`link[href*="/ui/css/"]`);
  for (let i = 0; i < commonJsFile.length; i++) {
    commonJsFile[i].remove();
  }
  for (let i = 0; i < commonCssFile.length; i++) {
    commonCssFile[i].remove();
  }
};
